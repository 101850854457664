<template>
    <div class="navmb">
        <div class="flex flexa">
            <el-icon :size="20" color="#FF6A00" class="icon bgshadow">
                <Back class="astyle" @click="$router.back()" />
            </el-icon>
            <span class="">知识库文档搜索</span>
        </div>
    </div>
    <p class="h20"></p>
    <div class="m-searchl">
        <div class="search">
            <el-input v-model="search_form.docTitle" style="max-width: 804px;background-color: #F7F7F7;"
                class="input-with-select" size="large" @keydown.enter="queryList">
            </el-input>
            <el-button color="#FF6A00" size="large" :disabled="loading ?? false" @click="queryList">
                <el-icon :size="12" color="#fff" class="icon">
                    <Search />
                </el-icon>
            </el-button>
        </div>
        <div class="history">
            <div class="ll">搜索历史</div>
            <div class="rr">
                <span
                    v-for="(item, index) in searchList"
                    :key="index"
                    @click="queryList(search_form.docTitle = item)"
                    class="point"
                >{{ item }}</span>
                <span class="point" style="color: #337eff" v-if="$store.getters['searchStore/get_search'].length" @click="$store.commit('searchStore/clearSearch')">清除历史</span>
            </div>
        </div>
    </div>
    <p class="h20"></p>
    <div class="m-resultl">
        <el-cascader @change="queryList" collapse-tags v-model="search_form.categoryId" class="w464 marr30" size="large" :props="cate.props"
            :options="cate.options" clearable placeholder="选择文档所属分类" />
        <el-select @change="queryList" class="w242" size="large" v-model="search_form.docType" clearable placeholder="选择文档类型">
            <el-option label="知识文档" :value="1" />
            <el-option label="Bug文档" :value="2" />
        </el-select>
        <div class="result" v-if="act == 1">
            <div class="item" v-loading="loading">
                <ul class="ul-listl3">
                    <li v-for="(item, index) in list" :key="index">
                        <router-link :to="`/knowledgeInfo/${item.id}`" class="con">
                            <!-- <div class="tit">新增Vastbase<span>数据</span>库请求结构</div> -->
                            <div class="tit" v-html="item.docTitle"></div>
                            <div class="desc" v-html="item.docContent">
                            </div>
                            <div class="stips">所属分类：{{ item.categoryName }}</div>
                        </router-link>
                    </li>
                </ul>
                <div class="m-pages">
                    <div class="pages">
                        <el-pagination v-model:currentPage="search.pageNumber" @update:page-size="getDocList()"
                            @update:current-page="getDocList" :page-sizes="[5, 10, 15, 20]"
                            layout=" prev, pager, next, sizes, jumper" :total="total">
                        </el-pagination>
                    </div>
                </div>
            </div>
        </div>
        <div class="result" v-if="act == 2">
            <div class="item">
                <div class="m-titl1 styl2">
                    <div class="tit">服务请求（19）</div>
                </div>
                <ul class="ul-listl2 styl2">
                    <li>
                        <a href="" class="con">
                            <div class="rr">工单编号：3-15372654391</div>
                            <div class="tit">监控主机上的agent目录<span>数据</span>是否会自动清理</div>
                        </a>
                    </li>
                    <li>
                        <a href="" class="con">
                            <div class="rr">工单编号：3-15372654391</div>
                            <div class="tit">帮忙告诉name-service call wait的<span>数据</span>详细信息</div>
                        </a>
                    </li>
                    <li>
                        <a href="" class="con">
                            <div class="rr">工单编号：3-15372654391</div>
                            <div class="tit">VD<span>数据</span>的迁移存储问题一不知道是什么原因</div>
                        </a>
                    </li>
                    <li>
                        <a href="" class="con">
                            <div class="rr">工单编号：3-15372654391</div>
                            <div class="tit">帮忙告诉name-service call wait的<span>数据</span>详细信息</div>
                        </a>
                    </li>
                    <li>
                        <a href="" class="con">
                            <div class="rr">工单编号：3-15372654391</div>
                            <div class="tit">帮忙告诉name-service call wait的<span>数据</span>详细信息</div>
                        </a>
                    </li>
                    <li>
                        <a href="" class="con">
                            <div class="rr">工单编号：3-15372654391</div>
                            <div class="tit">VD<span>数据</span>的迁移存储问题一不知道是什么原因</div>
                        </a>
                    </li>
                    <li>
                        <a href="" class="con">
                            <div class="rr">工单编号：3-15372654391</div>
                            <div class="tit">帮忙告诉name-service call wait的<span>数据</span>详细信息</div>
                        </a>
                    </li>
                </ul>
                <div class="m-pages">
                    <div class="pages">
                        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                            :current-page="search.pageNumber" :page-sizes="[5, 10, 15, 20]" :page-size="search.pageSize"
                            layout=" prev, pager, next, sizes, jumper" :total="total">
                        </el-pagination>
                    </div>
                </div>
            </div>
        </div>
        <div class="result" v-if="act == 3">
            <div class="item">
                <div class="m-titl1 styl2">
                    <div class="tit">海量资讯（19）</div>
                </div>
                <ul class="ul-listl2 styl2">
                    <li>
                        <a href="" class="con">
                            <div class="rr">2024-07-24 11:41:45</div>
                            <div class="tit">升级】web应用防火墙透明接入华北集群<span>数据</span>升级（2024年4月升级计划）SQL执行报错：ERROR:no re…</div>
                        </a>
                    </li>
                    <li>
                        <a href="" class="con">
                            <div class="rr">2024-07-24 11:41:45</div>
                            <div class="tit">SQL执行报错：ERROR:no relation entry for relid新增<span>数据</span>Vastbase数…</div>
                        </a>
                    </li>
                    <li>
                        <a href="" class="con">
                            <div class="rr">2024-07-24 11:41:45</div>
                            <div class="tit">升级】web应用防火墙透明接入华北集群<span>数据</span>升级（2024年4月升级计划）SQL执行报错：ERROR:no re…</div>
                        </a>
                    </li>
                    <li>
                        <a href="" class="con">
                            <div class="rr">2024-07-24 11:41:45</div>
                            <div class="tit">SQL执行报错：ERROR:no relation entry for relid新增<span>数据</span>Vastbase数…</div>
                        </a>
                    </li>
                    <li>
                        <a href="" class="con">
                            <div class="rr">2024-07-24 11:41:45</div>
                            <div class="tit">升级】web应用防火墙透明接入华北集群<span>数据</span>升级（2024年4月升级计划）SQL执行报错：ERROR:no re…</div>
                        </a>
                    </li>
                    <li>
                        <a href="" class="con">
                            <div class="rr">2024-07-24 11:41:45</div>
                            <div class="tit">SQL执行报错：ERROR:no relation entry for relid新增<span>数据</span>Vastbase数…</div>
                        </a>
                    </li>
                    <li>
                        <a href="" class="con">
                            <div class="rr">2024-07-24 11:41:45</div>
                            <div class="tit">升级】web应用防火墙透明接入华北集群<span>数据</span>升级（2024年4月升级计划）SQL执行报错：ERROR:no re…</div>
                        </a>
                    </li>
                </ul>
                <div class="m-pages">
                    <div class="pages">
                        <el-pagination @update:page-size="search.pageSize = $event; getDocList(1)"
                            @update:current-page="getDocList" :current-page="search.pageNo"
                            :page-sizes="[5, 10, 15, 20]" :page-size="search.pageSize"
                            layout=" prev, pager, next, sizes, jumper" :total="total">
                        </el-pagination>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
//图标
import { mapGetters } from 'vuex';
import { Back, Search } from '@element-plus/icons-vue'
import { debounce, throttle } from 'throttle-debounce'
export default {
    computed: {
        ...mapGetters({
            searchList: 'searchStore/get_search',
        }),
    },
    components: {
        Back, Search
    },

    data() {
        return {
            act: 1,
            loading: false,
            search_form: {
                categoryId: '',
                docType: '',
                docTitle: '',
            },
            search: {
                categoryId: '',
                docType: '',
                docTitle: '',
                pageNumber: 1,
                pageSize: 10
            },
            list: [],
            total: 0,
            cate: {
                props: {
                    value: 'id',
                    label: 'categoryTitle',
                    children: 'list',
                        multiple: true, 
                    checkStrictly: false,
                    emitPath: false,
                },
                options: [],
            }
        };
    },
    mounted() {
        this.docCateList()
    },
    methods: {
        async docCateList() {
            try {
                const { records, total } = await this.$api.know.docCateList({
                    pageNumber: 1,
                    pageSize: 2000
                })
                this.cate.options = records
            } catch (e) {
                return console.log(e)
            }
        },
        queryList: throttle(300, function() {
            this.search.categoryId = this.search_form.categoryId?this.search_form.categoryId.join(','):''
            this.search.docType = this.search_form.docType;
            this.search.docTitle = this.search_form.docTitle;
            this.$store.commit('searchStore/putSearch', this.search_form.docTitle)
            this.getDocList(1)
        }),
        async getDocList(pageNumber) {
            try {
                pageNumber && (this.search.pageNumber = pageNumber)
                this.loading = true
                console.log('搜索结果',this.search)
                let param = JSON.parse(JSON.stringify(this.search))
				delete param.docTitle
                const { records, total } = await this.$api.know.getDocLists(param,{docTitle:this.search.docTitle})
                // this.utils.html2Text(records, 'docContent')
                // this.utils.highLightText(records, this.search_form.docTitle, 'docTitle')
                // this.utils.highLightText(records, this.search_form.docContent, 'docContent')
                console.log('recordsrecordsrecordsrecordsrecords',records)
                this.list = records
                this.total = total
            } catch (e) {
                return console.log(e)
            } finally {
                this.loading = false
            }
        },
    },
    beforeRouteEnter(to, from, next) {
        next(vm => {
            const state = history.state
            vm.search.categoryId = state.categoryId?state.categoryId.split(','):''
            vm.search.docType = state.docType
            vm.search.docTitle = state.docTitle
            vm.search_form.categoryId = state.categoryId?state.categoryId.split(','):''
            vm.search_form.docType = state.docType
            vm.search_form.docTitle = state.docTitle
            vm.queryList()
        })
    }
};
</script>

<style lang="scss" scoped>
.navmb {
    .icon {
        margin-right: 10px;
    }
}

.m-searchl {
    overflow: hidden;

    .search {
        overflow: hidden;
        margin-bottom: 20px;
    }

    .history {
        overflow: hidden;

        .ll {
            float: left;
            font-size: 12px;
            color: #999999;
            line-height: 30px;
            margin-right: 15px;
        }

        .rr {
            overflow: hidden;

            span {
                display: block;
                float: left;
                min-width: 82px;
                height: 30px;
                line-height: 30px;
                text-align: center;
                font-size: 12px;
                color: #666666;
                margin-right: 20px;
                padding: 0 15px;
            }
        }
    }
}

.m-resultl {
    overflow: hidden;
    background-color: #fff;
    padding: 20px 30px;

    .tips {
        font-size: 12px;
        color: #4C4C4C;
        line-height: 1.416em;
        overflow: hidden;
        margin-bottom: 20px;
    }

    .result {
        overflow: hidden;

        .item {
            overflow: hidden;
            margin-bottom: 19px;
        }
    }
}

.ul-listl1 {
    font-size: 0;
    margin-bottom: 30px;

    li {
        display: inline-block;
        margin-right: 10px;

        .con {
            display: block;
            width: 120px;
            height: 32px;
            line-height: 32px;
            background: #F7F7F7;
            text-align: center;
            display: block;
            font-size: 12px;
            color: #181819;
            transition: .5s;
            cursor: pointer;
        }

        .con:hover {
            color: #fff;
            background: #FF6A00;
            box-shadow: 0px 2px 8px 0px #FFC49A;
        }
    }

    li.on {
        .con {
            color: #fff;
            background: #FF6A00;
            box-shadow: 0px 2px 8px 0px #FFC49A;
        }
    }
}

.m-titl1 {
    overflow: hidden;
    padding-bottom: 11px;
    border-bottom: 1px solid #E8E9EB;
    margin-bottom: 26px;

    .more {
        float: right;
        font-size: 12px;
        color: #ff6a00;
        line-height: 1.416em;
        transition: .5s;
    }

    .more:hover {
        color: #f83;
    }

    .tit {
        font-size: 12px;
        color: #4C4C4C;
        line-height: 1.416em;
        overflow: hidden;
    }
}

.m-titl1.styl2 {
    border-bottom: none;
    padding-bottom: 0;
    margin-bottom: 10px;
}

.ul-listl2 {
    overflow: hidden;

    li {
        margin-bottom: 21px;
        overflow: hidden;

        .con {
            display: block;
            overflow: hidden;

            .rr {
                float: right;
                max-width: 350px;
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
                font-size: 12px;
                line-height: 1.5em;
                color: #9c9c9c;
                margin-left: 20px;
            }

            .tit {
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
                font-size: 12px;
                line-height: 1.5em;
                color: #151822;

                span {
                    color: #ff6a00;
                }

                transition: .5s;
            }
        }

        .con:hover {
            .tit {
                color: #ff6a00;
            }
        }
    }
}

.ul-listl2.styl2 {
    margin-bottom: 57px;

    li {
        margin-bottom: 0;

        .con {
            padding: 30px 0 24px;
            border-bottom: 1px dashed #E6E6E6;
        }
    }
}

.ul-listl3 {
    overflow: hidden;
    margin-bottom: 40px;

    .con {
        display: block;
        overflow: hidden;
        padding: 20px 0 17px;
        border-bottom: 1px dashed #E6E6E6;

        .tit {
            font-size: 14px;
            line-height: 1.583em;
            color: #151822;
            margin-bottom: 10px;
            transition: .5s;

            span {
                color: #ff6a00;
            }
        }

        .desc {
            font-size: 12px;
            color: #686868;
            line-height: 1.666em;
            margin-bottom: 10px;
            text-overflow: ellipsis;
            display: -webkit-box;
            overflow: hidden;
            overflow-wrap: break-word;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
        }

        .stips {
            font-size: 12px;
            line-height: 2.333em;
            overflow: hidden;
            color: #9c9c9c;
        }
    }

    .con:hover {
        .tit {
            color: #ff6a00;
        }
    }
}

.m-pages {
    overflow: hidden;
    text-align: center;
    font-size: 0;
    margin-bottom: 10px;

    .pages {
        display: inline-block;
        vertical-align: middle;
    }
}
</style>