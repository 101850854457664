<template>
    <div class="navmb">
        <div class="flex flexa">
            <el-icon :size="20" color="#FF6A00" class="icon astyle bgshadow" @click="$router.back()">
                <Back />
            </el-icon>
            <span class="">搜索</span>
        </div>
    </div>
    <p class="h20"></p>
    <div class="m-searchl">
        <div class="search">
            <el-input
                :modelValue="search"
                autofocus
                style="max-width: 804px;background-color: #F7F7F7;"
                class="input-with-select"
                size="large"
                @input="$store.commit('bigSearch/setSearch', $event)"
                @change="$store.dispatch('bigSearch/multiActionLine').then((o) => $store.commit('searchStore/putSearch', o))"
            >
            </el-input>
            <el-button color="#FF6A00" size="large" @click="$store.dispatch('bigSearch/multiActionLine').then((o) => $store.commit('searchStore/putSearch', o))">
                <el-icon :size="12" color="#fff" class="icon">
                    <Search />
                </el-icon>
            </el-button>
        </div>
        <div class="history">
            <div class="ll">搜索历史</div>
            <div class="rr">
                <span
                    v-for="(item, index) in searchList"
                    :key="index"
                    @click="$store.commit('bigSearch/setSearch', item);$store.dispatch('bigSearch/multiActionLine')"
                    class="point"
                >{{ item }}</span>
                <span
                    v-if="$store.getters['searchStore/get_search'].length" class="hisitem point" style="color: #337eff" @click="$store.commit('searchStore/clearSearch')"
                >清除历史</span>
            </div>
        </div>
    </div>
    <p class="h20"></p>
    <div class="m-resultl">
        <div class="tips">搜索“{{ enterSearch }}”的结果，共找到{{ getTotal.sum }}条</div>
        <ul class="ul-listl1 fix">
            <li @click="search_tab = 0" :class="{ on: search_tab == 0 }">
                <div class="con">全部</div>
            </li>
            <li @click="search_tab = 1" :class="{ on: search_tab == 1 }">
                <div class="con">知识库文档（{{ getTotal.doc > 99 ? '99+': getTotal.doc }}）</div>
            </li>
            <!-- <li @click="search_tab = 2" :class="{ on: search_tab == 2 }">
                <div class="con">服务请求（{{ getTotal.request > 99 ? '99+': getTotal.request }}）</div>
            </li> -->
            <li @click="search_tab = 3" :class="{ on: search_tab == 3 }">
                <div class="con">海量资讯（{{ getTotal.announ > 99 ? '99+': getTotal.announ }}）</div>
            </li>
        </ul>
        <div class="result" v-if="search_tab == 0">
            <div class="item">
                <div class="m-titl1">
                    <a href="JavaScript:void(0)" class="more" @click="search_tab = 1">查看更多 ></a>
                    <div class="tit">知识库文档（{{ getTotal.doc > 99 ? '99+': getTotal.doc }}）</div>
                </div>
                <ul class="ul-listl2">
                    <li
                        v-for="(item, index) in getDoc"
                        :key="index"
                    >
                        <a href="JavaScript:void(0)" class="con" @click="$router.push({
                            name: `knowledgeInfo`,
                            params: {
                                id: item.id
                            }
                        })">
                            <div class="rr">所属分类：{{ item.categoryName }}</div>
                            <div class="tit" v-html="item.docTitle"></div>
                        </a>
                    </li>
                </ul>
            </div>
            <!-- <div class="item">
                <div class="m-titl1">
                    <a href="JavaScript:void(0)" class="more" @click="search_tab = 2">查看更多 ></a>
                    <div class="tit">服务请求（{{ getTotal.request > 99 ? '99+': getTotal.request }}）</div>
                </div>
                <ul class="ul-listl2">
                    <li
                        v-for="(item, index) in getRequest"
                        :key="index"
                    >
                        <a href="JavaScript:void(0)" class="con" @click="$router.push({
                            name: 'serviceInfo',
                            params: {
                                id: item.id
                            }
                        })">
                            <div class="rr">工单编号：{{ item.outTradeNo }}</div>
                            <div class="tit" v-html="item.problemProfile"></div>
                        </a>
                    </li>
                </ul>
            </div> -->
            <div class="item">
                <div class="m-titl1">
                    <a href="JavaScript:void(0)" class="more"  @click="search_tab = 3">查看更多 ></a>
                    <div class="tit">海量资讯（{{ getTotal.announ > 99 ? '99+': getTotal.announ }}）</div>
                </div>
                <ul class="ul-listl2">
                    <li
                        v-for="(item, index) in getAnnoun"
                        :key="index"
                    >
                        <a href="JavaScript:void(0)" class="con" @click="$router.push({
                            name: 'newsDetail',
                            params: {
                                id: item.id
                            }
                        })">
                            <div class="rr">{{ item.createTime }}</div>
                            <div class="tit" v-html="item.title"></div>
                        </a>
                    </li>
                </ul>
            </div>
        </div>
        <div class="result" v-else-if="search_tab == 1" v-loading="docState.loading">
            <div class="item">
                <div class="m-titl1 styl2">
                    <!-- <a href="" class="more">查看更多 ></a> -->
                    <div class="tit">知识库文档（{{ getTotal.doc > 99 ? '99+': getTotal.doc }}）</div>
                </div>
                <ul class="ul-listl3">
                    <li
                        v-for="(item, index) in getDoc"
                        :key="index"
                    >
                        <a href="JavaScript:void(0)" class="con" @click="$router.push({
                            name: `knowledgeInfo`,
                            params: {
                                id: item.id
                            }
                        })">
                            <div class="tit" v-html="item.docTitle"></div>
                            <div class="desc" v-html="item.docContent">
                            </div>
                            <div class="stips">所属分类：{{ item.categoryName }}</div>
                        </a>
                    </li>
                </ul>
                <div class="m-pages">
                    <div class="pages">
                        <el-pagination
                            @update:page-size="store.commit('bigSearch/setPageSizeDoc', $event);$store.dispatch('bigSearch/pagina_Doc')"
                            @update:current-page="$store.dispatch('bigSearch/pagina_Doc', $event)"
                            :current-page="docState.param.pageNumber"
                            :page-sizes="[5, 10, 15, 20]"
                            :page-size="docState.param.pageSize"
                            layout=" prev, pager, next, sizes, jumper"
                            :total="docState.total"
                        >
                        </el-pagination>
                    </div>
                </div>
            </div>
        </div>
        <!-- <div class="result" v-else-if="search_tab == 2" v-loading="requestState.loading">
            <div class="item">
                <div class="m-titl1 styl2">
                    <div class="tit">服务请求（{{ getTotal.request > 99 ? '99+': getTotal.request }}）</div>
                </div>
                <ul class="ul-listl2 styl2">
                    <li
                        v-for="(item, index) in getRequest"
                        :key="index"
                    >
                        <a href="JavaScript:void(0)" class="con" @click="$router.push({
                            name: `serviceInfo`,
                            params: {
                                id: item.id
                            }
                        })">
                            <div class="rr">工单编号：{{ item.outTradeNo }}</div>
                            <div class="tit" v-html="item.problemProfile"></div>
                        </a>
                    </li>
                </ul>
                <div class="m-pages">
                    <div class="pages">
                        <el-pagination
                            @update:page-size="store.commit('bigSearch/setPageSizeRequest', $event);$store.dispatch('bigSearch/pagina_Request')"
                            @update:current-page="$store.dispatch('bigSearch/pagina_Request', $event)"
                            :current-page="requestState.param.pageNumber"
                            :page-sizes="[5, 10, 15, 20]"
                            :page-size="requestState.param.pageSize"
                            layout=" prev, pager, next, sizes, jumper"
                            :total="requestState.total"
                        >
                        </el-pagination>
                    </div>
                </div>
            </div>
        </div> -->
        <div class="result" v-else-if="search_tab == 3" v-loading="announState.loading">
            <div class="item">
                <div class="m-titl1 styl2">
                    <div class="tit">海量资讯（{{ getTotal.announ > 99 ? '99+': getTotal.announ }}）</div>
                </div>
                <ul class="ul-listl2 styl2">
                    <li
                        v-for="(item, index) in getAnnoun"
                        :key="index"
                    >
                        <a href="JavaScript:void(0)" class="con" @click="$router.push({
                            name: 'newsDetail',
                            params: {
                                id: item.id
                            }
                        })">
                            <div class="rr">{{ item.createTime }}</div>
                            <div class="tit" v-html="item.title"></div>
                        </a>
                    </li>
                </ul>
                <div class="m-pages">
                    <div class="pages">
                        <el-pagination
                            @update:page-size="store.commit('bigSearch/setPageSizeAnnoun', $event);$store.dispatch('bigSearch/pagina_Announ')"
                            @update:current-page="$store.dispatch('bigSearch/pagina_Announ', $event)"
                            :current-page="announState.param.pageNumber"
                            :page-sizes="[5, 10, 15, 20]"
                            :page-size="announState.param.pageSize"
                            layout=" prev, pager, next, sizes, jumper"
                            :total="announState.total"
                        >
                        </el-pagination>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
//图标
import { Back, Search } from '@element-plus/icons-vue'
import { mapState, mapGetters } from 'vuex';
export default {
    components: {
        Back, Search
    },
    computed: {
        ...mapState({
            search: state => state.bigSearch.search,
            enterSearch: state => state.bigSearch.enterSearch,
            docState: state => state.bigSearch.doc,
            announState: state => state.bigSearch.announ,
            requestState: state => state.bigSearch.request,
        }),
        ...mapGetters({
            getTotal: 'bigSearch/getTotal',
            getDoc: 'bigSearch/getDoc',
            getAnnoun: 'bigSearch/getAnnoun',
            getRequest: 'bigSearch/getRequest',
            searchList: 'searchStore/get_search',
        }),
    },
    data() {
        return {
            search_tab: 0,
        };
    },
    mounted() {
    },
    methods: {
    },
    beforeRouteEnter(to, from, next) {
        next(vm => {
            const state = history.state
            if ([1, 2, 3].includes(state.search_tab)) {
                vm.search_tab = state.search_tab
            } else {
                vm.search_tab = 0
            }
            if (state.search) {
                vm.$store.commit('bigSearch/setSearch', state.search)
            }
            vm.$store.dispatch('bigSearch/multiActionLine').then((o) => vm.$store.commit('searchStore/putSearch', o))
        })
    }
};
</script>

<style lang="scss" scoped>
.navmb {
    .icon {
        margin-right: 10px;
    }
}

.m-searchl {
    overflow: hidden;
    background: #fff;
    padding: 20px;

    .search {
        overflow: hidden;
        margin-bottom: 20px;
    }

    .history {
        overflow: hidden;

        .ll {
            float: left;
            font-size: 12px;
            color: #999999;
            line-height: 30px;
            margin-right: 15px;
        }

        .rr {
            overflow: hidden;

            span {
                display: block;
                float: left;
                min-width: 82px;
                height: 30px;
                line-height: 30px;
                background: #F0F0F0;
                text-align: center;
                font-size: 12px;
                color: #666666;
                margin-right: 20px;
                padding: 0 15px;
            }
        }
    }
}

.m-resultl {
    overflow: hidden;
    background-color: #fff;
    padding: 20px 30px;

    .tips {
        font-size: 12px;
        color: #4C4C4C;
        line-height: 1.416em;
        overflow: hidden;
        margin-bottom: 20px;
    }

    .result {
        overflow: hidden;

        .item {
            overflow: hidden;
            margin-bottom: 19px;
        }
    }
}

.ul-listl1 {
    font-size: 0;
    margin-bottom: 30px;

    li {
        display: inline-block;
        margin-right: 10px;

        .con {
            display: block;
            width: 120px;
            height: 32px;
            line-height: 32px;
            background: #F7F7F7;
            text-align: center;
            display: block;
            font-size: 12px;
            color: #181819;
            transition: .5s;
            cursor: pointer;
        }

        .con:hover {
            color: #fff;
            background: #FF6A00;
            box-shadow: 0px 2px 8px 0px #FFC49A;
        }
    }

    li.on {
        .con {
            color: #fff;
            background: #FF6A00;
            box-shadow: 0px 2px 8px 0px #FFC49A;
        }
    }
}

.m-titl1 {
    overflow: hidden;
    padding-bottom: 11px;
    border-bottom: 1px solid #E8E9EB;
    margin-bottom: 26px;

    .more {
        float: right;
        font-size: 12px;
        color: #ff6a00;
        line-height: 1.416em;
        transition: .5s;
    }

    .more:hover {
        color: #f83;
    }

    .tit {
        font-size: 12px;
        color: #4C4C4C;
        line-height: 1.416em;
        overflow: hidden;
    }
}

.m-titl1.styl2 {
    border-bottom: none;
    padding-bottom: 0;
    margin-bottom: 10px;
}

.ul-listl2 {
    overflow: hidden;

    li {
        margin-bottom: 21px;
        overflow: hidden;

        .con {
            display: block;
            overflow: hidden;

            .rr {
                float: right;
                max-width: 350px;
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
                font-size: 12px;
                line-height: 1.5em;
                color: #9c9c9c;
                margin-left: 20px;
            }

            .tit {
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
                font-size: 12px;
                line-height: 1.5em;
                color: #151822;

                span {
                    color: #ff6a00;
                }

                transition: .5s;
            }
        }

        .con:hover {
            .tit {
                color: #ff6a00;
            }
        }
    }
}

.ul-listl2.styl2 {
    margin-bottom: 57px;

    li {
        margin-bottom: 0;

        .con {
            padding: 30px 0 24px;
            border-bottom: 1px dashed #E6E6E6;
        }
    }
}

.ul-listl3 {
    overflow: hidden;
    margin-bottom: 40px;

    .con {
        display: block;
        overflow: hidden;
        padding: 20px 0 17px;
        border-bottom: 1px dashed #E6E6E6;

        .tit {
            font-size: 14px;
            line-height: 1.583em;
            color: #151822;
            margin-bottom: 10px;
            transition: .5s;

            span {
                color: #ff6a00;
            }
        }

        .desc {
            font-size: 14px;
            color: #686868;
            line-height: 2em;
            overflow: hidden;
            margin-bottom: 6px;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: normal; /* 确保文本正常换行 */
        }

        .stips {
            font-size: 12px;
            line-height: 2.333em;
            overflow: hidden;
            color: #9c9c9c;
        }
    }

    .con:hover {
        .tit {
            color: #ff6a00;
        }
    }
}

.m-pages {
    overflow: hidden;
    text-align: center;
    font-size: 0;
    margin-bottom: 10px;

    .pages {
        display: inline-block;
        vertical-align: middle;
    }
}
</style>