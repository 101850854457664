import api from '@/utils/api.js'
import utils from '@/utils/utils.js'

// 搜索总线
export default {
    namespaced: true,
    state: {
        search: '',
        enterSearch: '',
        doc: {
            loading: false,
            param: {
                docTitle: '',
                pageNumber: 1,
                pageSize: 10,
                isSearchCompany:0,
            },
            list: [],
            total: 0,
        },
        announ: {
            loading: false,
            param: {
                type: '',
                title: '',
                pageNumber: 1,
                pageSize: 10,
            },
            list: [],
            total: 0,
        },
        request: {
            loading: false,
            param: {
                problemProfile: '',
                pageNumber: 1,
                pageSize: 10,
            },
            list: [],
            total: 0,
        }
    },
    getters: {
        getTotal(state) {
            return {
                sum: state.doc.total + state.announ.total + state.request.total,
                doc: state.doc.total,
                announ: state.announ.total,
                request: state.request.total,
            }
        },
        getDoc(state) {
            console.log('state.doc.liststate.doc.liststate.doc.list',state.doc.list)
            return state.doc.list
        },
        getAnnoun(state) {
            return state.announ.list
        },
        getRequest(state) {
            return state.request.list
        },
    },
    mutations: {
        setDoc(state, payload) {
            // utils.html2Text(payload.records, 'docContent')
            // utils.highLightText(payload.records, state.enterSearch, 'docTitle')
            // utils.highLightText(payload.records, state.enterSearch, 'docContent')
            state.doc.list = payload.records
            state.doc.total = payload.total
        },
        setAnnoun(state, payload) {
            // utils.highLightText(payload.records, state.enterSearch, 'title')
            state.announ.list = payload.records
            state.announ.total = payload.total
        },
        setRequest(state, payload) {
            return
            utils.highLightText(payload.records, state.enterSearch, 'problemProfile')
            state.request.list = payload.records
            state.request.total = payload.total
        },
        setSearch(state, payload) {
            state.search = payload
        },
        // 设置分页大小
        setPageSizeDoc(state, payload) {
            state.doc.param.pageSize = payload
        },
        setPageSizeAnnoun(state, payload) {
            state.announ.param.pageSize = payload
        },
        setPageSizeRequest(state, payload) {
            state.request.param.pageSize = payload
        },
    },
    actions: {
        // 集合搜索线
        async multiActionLine({ commit, state, getters }) {
            try {
                state.doc.param.docTitle = state.search
                state.announ.param.title = state.search
                state.request.param.problemProfile = state.search
                state.enterSearch = state.search
                let param = JSON.parse(JSON.stringify(state.doc.param))
				delete param.docTitle
                const [doc, announ, request] = await Promise.all([
                    api.know.getIndexDocList(param,{docTitle:state.doc.param.docTitle}),
                    api.base.announcementDynamics(state.announ.param),
                    // api.tech.serviceRerviceEsRequest(state.request.param)
                ])
                commit('setDoc', doc)
                commit('setAnnoun', announ)
                commit('setRequest', request)
                return Promise.resolve(state.search)
            } catch (error) {
                return Promise.reject(error)
            }
        },
        async pagina_Doc({ commit, state, getters }, payload) {
            try {
                payload && (state.doc.param.pageNumber = payload)
                state.doc.loading = true
                let param = JSON.parse(JSON.stringify(state.doc.param))
				delete param.docTitle
                const doc = await api.know.getDocLists(param,{docTitle:state.doc.param.docTitle})
                commit('setDoc', doc)
                return Promise.resolve()
            } catch (e) {
                return Promise.reject(e)
            } finally {
                state.doc.loading = false
            }
        },
        async pagina_Announ({ commit, state, getters }, payload) {
            try {
                payload && (state.announ.param.pageNumber = payload)
                state.announ.loading = true
                const announ = await api.base.announcementDynamics(state.announ.param)
                commit('setAnnoun', announ)
                return Promise.resolve()
            } catch (e) {
                return Promise.reject(e)
            } finally {
                state.announ.loading = false
            }
        },
        async pagina_Request({ commit, state, getters }, payload) {
            try {
                payload && (state.request.param.pageNumber = payload)
                state.request.loading = true
                const request = await api.tech.serviceRequest(state.request.param)
                commit('setRequest', request)
                return Promise.resolve()
            } catch (e) {
                return Promise.reject(e)
            } finally {
                state.request.loading = false
            }
        }
    }
}
